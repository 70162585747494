<script setup lang="ts">
import { computed } from "vue"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"

interface Props {
  label?: string | undefined
  modelValue?: boolean
  size?: "small" | "medium" | "large"
  color?: "neutral" | "indigo" | "emerald" | "amber"
  boxed?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  modelValue: true,
  size: "medium",
  color: "neutral",
  boxed: false,
})

const emit = defineEmits(["toggle", "update:modelValue"])

const switchSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-3 w-6 border-2",
  medium: "h-4 w-8 border-2",
  large: "h-6 w-12 border-4",
}

const switchButtonTranslateClasses: {
  [K in NonNullable<Props["size"]>]: string
} = {
  small: "translate-x-3",
  medium: "translate-x-4",
  large: "translate-x-6",
}

const switchButtonSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-2 w-2",
  medium: "h-3 w-3",
  large: "h-4 w-4",
}

const labelSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "ml-2 text-xs",
  medium: "ml-2 text-sm",
  large: "ml-3 text-m",
}

const boxedLabelSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "ml-2 text-xs",
  medium: "ml-2 text-xs",
  large: "ml-3 text-m",
}

const boxSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-6 px-2",
  medium: "h-8 px-2",
  large: "h-12 px-3",
}

const enabledColorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  neutral: "bg-indigo-500 focus:ring-indigo-600",
  indigo: "bg-indigo-500 focus:ring-indigo-600",
  emerald: "bg-emerald-500 focus:ring-emerald-600",
  amber: "bg-amber-500 focus:ring-amber-600",
}

const disabledColorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  neutral: "bg-slate-200 focus:ring-indigo-200",
  indigo: "bg-indigo-200 focus:ring-indigo-200",
  emerald: "bg-emerald-200 focus:ring-emerald-200",
  amber: "bg-amber-200 focus:ring-amber-200",
}

const labelColorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  neutral: "text-slate-600",
  indigo: "text-indigo-700",
  emerald: "text-emerald-700",
  amber: "text-amber-700",
}

const boxColorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  neutral: "bg-slate-50 border-slate-200",
  indigo: "bg-indigo-50 border-indigo-200",
  emerald: "bg-emerald-50 border-emerald-200",
  amber: "bg-amber-50 border-amber-200",
}

const enabled = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
    emit("toggle", value)
  },
})

const classes = computed(() => {
  const classes = [switchSizeClasses[props.size]]
  classes.push(
    enabled.value
      ? enabledColorClasses[props.color]
      : disabledColorClasses[props.color]
  )
  return classes.join(" ")
})

const switchButtonClasses = computed(() => {
  const classes = [switchButtonSizeClasses[props.size]]
  classes.push(
    enabled.value ? switchButtonTranslateClasses[props.size] : "translate-x-0"
  )
  return classes.join(" ")
})

const labelClasses = computed(() => {
  const classes = [labelColorClasses[props.color]]
  classes.push(
    props.boxed
      ? boxedLabelSizeClasses[props.size]
      : labelSizeClasses[props.size]
  )
  return classes.join(" ")
})

const boxClasses = computed(() => {
  if (!props.boxed) return ""
  const classes = [
    "rounded border flex items-center",
    boxColorClasses[props.color],
    boxSizeClasses[props.size],
  ]
  return classes.join(" ")
})
const boxContainerClasses = computed(() => {
  if (!props.boxed) return ""
  const classes = [
    "flex items-center h-8 pr-2 border-r border-t border-b",
    boxColorClasses[props.color],
  ]
  return classes.join(" ")
})
</script>

<template>
  <div :class="boxClasses">
    <SwitchGroup as="div" class="flex items-center">
      <div :class="boxContainerClasses">
        <Switch
          v-model="enabled"
          :class="classes"
          class="relative inline-flex items-center flex-shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <span
            aria-hidden="true"
            :class="switchButtonClasses"
            class="pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          />
        </Switch>
      </div>
      <SwitchLabel
        v-if="label"
        as="span"
        :class="labelClasses"
        class="cursor-pointer"
      >
        <span class="font-medium">{{ label }}</span>
      </SwitchLabel>
    </SwitchGroup>
  </div>
</template>
